var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("detail-page", {
    ref: _vm.detailPageRefName,
    attrs: {
      headerTitle: _vm.$t("exportinvoicesf"),
      tooltipMsg: "pimanualofexpor3399",
      formSchema: _vm.schema,
      formModel: _vm.model,
    },
    scopedSlots: _vm._u([
      {
        key: "formActions",
        fn: function () {
          return [
            _c("megau-button", {
              attrs: {
                classprop: "btn btn-success",
                icon: "check",
                title: _vm.$t("exportinvoices3401"),
              },
              on: {
                handleClick: function ($event) {
                  return _vm.createExport()
                },
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "afterForm",
        fn: function () {
          return [
            _c("div", { staticClass: "export-results" }, [
              _c("div", { staticClass: "export-results__container" }, [
                _c("div", { staticClass: "export-results__invoices" }, [
                  _vm.InvoicesFileFullUrl
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.InvoicesFileFullUrl,
                            download: "",
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.InvoicesFileUrlText) +
                              "\n\t\t\t\t\t"
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c("div", [
                  _vm.SummaryFileFullUrl
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.SummaryFileFullUrl,
                            download: "",
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.SummaryFilelUrlText) +
                              "\n\t\t\t\t\t"
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }