<template>
	<detail-page
		:headerTitle="$t('exportinvoicesf')"
		tooltipMsg="pimanualofexpor3399"
		:formSchema="schema"
		:formModel="model"
		:ref="detailPageRefName"
	>
		<template #formActions>
			<megau-button
				classprop="btn btn-success"
				icon="check"
				:title="$t('exportinvoices3401')"
				@handleClick="createExport()"
			></megau-button>
		</template>

		<template #afterForm>
			<div class="export-results">
				<div class="export-results__container">
					<div class="export-results__invoices">
						<a v-if="InvoicesFileFullUrl" :href="InvoicesFileFullUrl" download target="_blank">
							{{ InvoicesFileUrlText }}
						</a>
					</div>
					<div>
						<a v-if="SummaryFileFullUrl" :href="SummaryFileFullUrl" download target="_blank">
							{{ SummaryFilelUrlText }}
						</a>
					</div>
				</div>
			</div>
		</template>
	</detail-page>
</template>

<script>
import vueFormGenerator from 'vue-form-generator';
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import loading from '@/mixins/loading.mixin';
import http from '@/services/helpers/http';

export default {
	components: {
		DetailPage,
	},

	mixins: [loading, detailPageMixin],

	data() {
		return {
			controllerName: 'AccountingExport',
			modelFromRoute: false,
			model: {
				AccountingCompanyId: null,
				From: null,
				To: null,
			},
			schema: {
				groups: [
					{
						fields: [
							{
								type: 'select',
								label: '',
								model: 'AccountingCompanyId',
								required: true,
								validator: vueFormGenerator.validators.integer,
								i18n: {
									label: 'company',
									selectOptions: {
										noneSelectedText: 'select',
									},
								},
								values: [],
								selectOptions: {
									value: 'AcompanyId',
									name: 'AcompanyName',
								},
								styleClasses: 'half-width',
							},
							{
								type: 'single-datepicker',
								model: 'From',
								required: true,
								autoselect: true,
								language: 'sk',
								showtime: false,
								validator: vueFormGenerator.validators.date,
								i18n: {
									label: 'periodfrom',
								},
							},
							{
								type: 'single-datepicker',
								model: 'To',
								required: true,
								autoselect: true,
								language: 'sk',
								showtime: false,
								validator: vueFormGenerator.validators.date,
								i18n: {
									label: 'periodto',
								},
							},
						],
					},
				],
			},
			filesPaths: {
				InvoicesFileUrl: null,
				SummaryFileUrl: null,
			},
		};
	},

	computed: {
		InvoicesFileUrlText() {
			return this.createFileUrlText(this.filesPaths.InvoicesFileUrl);
		},

		SummaryFilelUrlText() {
			return this.createFileUrlText(this.filesPaths.SummaryFileUrl);
		},

		InvoicesFileFullUrl() {
			return this.createFileFullUrl(this.filesPaths.InvoicesFileUrl);
		},

		SummaryFileFullUrl() {
			return this.createFileFullUrl(this.filesPaths.SummaryFileUrl);
		},
	},

	methods: {
		async loadResources() {
			const [filesPaths, accCompanies] = await Promise.all([
				http.get(`${this.controllerName}/GetResult`).then((res) => res.data),
				this.loadAccountingCompanies(),
			]);

			this.filesPaths = filesPaths;
			this.updateSchemaFields({ AccountingCompanyId: { values: accCompanies } });
		},

		async createExport() {
			this.startLoading();

			const isValid = await this.$refs[this.detailPageRefName].validate();
			if (!isValid) {
				this.stopLoading();
				return;
			}

			const dtoModel = this.transformModelToDto(this.model);
			const res = await http
				.post(`${this.controllerName}/Export`, dtoModel)
				.then((res) => res.data)
				.finally(() => this.stopLoading());
			this.filesPaths = res;
		},

		loadAccountingCompanies() {
			return http.get('AccountingCompany/List').then((res) => res.data);
		},

		createFileUrlText(fileUrl) {
			if (!fileUrl) {
				return null;
			}

			let index = fileUrl.lastIndexOf('\\');
			if (index === -1) {
				index = fileUrl.lastIndexOf('/');
			}

			return index === -1 ? fileUrl : fileUrl.substring(index + 1, fileUrl.length);
		},

		createFileFullUrl(fileUrl) {
			if (!fileUrl) {
				return null;
			}

			return `${process.env.VUE_APP_ROOT}${fileUrl}`;
		},
	},
};
</script>

<style lang="scss" scoped>
.export-results {
	border-top: 1px solid #ccc;
	margin-top: 25px;

	> .export-results__container {
		margin-left: 18%;
		margin-top: 25px;
	}

	.export-results__invoices {
		margin-bottom: 10px;
	}
}
</style>